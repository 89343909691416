import * as Klaro from 'klaro/dist/klaro-no-css'

const config = {
	acceptAll: true,
	translations: {
		zz: {
			privacyPolicyUrl: '@/page/iKW7OaYbXobVaS5v',
		},
		de: {
			decline: 'Ablehnen',
			ok: 'Alles akzeptieren',
			privacyPolicyUrl: '/de/datenschutz',
			consentModal: {
				description: 'Wir möchten unsere Website optimal gestalten, ihre Leistung stetig verbessern und Ihnen auf Ihre Interessen zugeschnittene Inhalte zur Verfügung stellen. Zu diesem Zweck analysieren wir – mit Ihrer Zustimmung – die Nutzung unserer Website mithilfe von Tracking Tools und Cookies. Weitere Informationen zum Einsatz von Cookies und Analysetools sowie Widerspruchsmöglichkeiten finden Sie in unserer Datenschutzerklärung.',
			},
			consentNotice: {
				learnMore: 'Einstellungen',
				description: 'Wir möchten unsere Website optimal gestalten, ihre Leistung stetig verbessern und Ihnen auf Ihre Interessen zugeschnittene Inhalte zur Verfügung stellen. Zu diesem Zweck analysieren wir – mit Ihrer Zustimmung – die Nutzung unserer Website mithilfe von Tracking Tools und Cookies.',
			},
			purposes: {
				marketing: {
					title: 'Marketing',
					description: 'Diese Technologien werden von Werbetreibenden verwendet, um Anzeigen zu schalten, die für Ihre Interessen relevant sind.',
				},
				functional: {
					title: 'Funktionell',
					description: 'Diese Technologien ermöglichen es uns, die Nutzung der Website zu analysieren, um die Leistung zu messen und zu verbessern.',
				},
				essential: {
					title: 'Essenziell',
					description: 'Diese Technologien sind erforderlich, um die Kernfunktionalität der Website zu aktivieren.',
				},
			},
		},
		en: {
			decline: 'Decline',
			ok: 'Accept all',
			privacyPolicyUrl: '/en/privacy',
			consentModal: {
				description: 'This tool helps you to select and deactivate various tags / trackers / analytic tools used on this website.',
			},
			consentNotice: {
				learnMore: 'Settings',
				description: 'We want to make our website optimal, constantly improve its performance and provide you with content tailored to your interests. For this purpose, we analyze - with your consent - the use of our website using Tracking tools and Cookies.',
			},
			purposes: {
				marketing: {
					title: 'Marketing',
					description: 'These technologies are used by advertisers to serve ads that are relevant to your interests.',
				},
				functional: {
					title: 'Funktionell',
					description: 'These technologies enable us to analyse the use of the website in order to measure and improve performance.',
				},
				essential: {
					title: 'Essenziell',
					description: 'These technologies are required to activate the core functionality of the website.',
				},
			},
		},
		fr: {
			decline: 'Refuser',
			ok: 'Accepter tout',
			privacyPolicyUrl: '/fr/politique-de-confidentialite',
			consentModal: {
				description: 'Cet outil vous aide à sélectionner et à désactiver divers tags / trackers / tools d’analyse utilisés sur ce site.',
			},
			consentNotice: {
				learnMore: 'Plus',
				description: 'Nous souhaitons rendre notre site Internet optimal, améliorer constamment ses performances et vous proposer un contenu adapté à vos intérêts. À cette fin, nous analysons - avec votre consentement - l’utilisation de notre site Internet à l’aide d’outils de Tracking et de Cookies.',
			},
			purposes: {
				marketing: {
					title: 'Marketing',
					description: 'Ces technologies sont utilisées par les annonceurs pour diffuser des publicités qui correspondent à vos intérêts.',
				},
				functional: {
					title: 'Fonctionnel',
					description: 'Ces technologies nous permettent d’analyser l’utilisation du site web afin de mesurer et d’améliorer les performances.',
				},
				essential: {
					title: 'Essentiel',
					description: 'Ces technologies sont nécessaires pour activer les fonctionnalités de base du site web.',
				},
			},
		},
		es: {
			decline: 'Denegar',
			ok: 'Aceptar todo',
			privacyPolicyUrl: '/es/privacidad',
			consentModal: {
				description: 'Esta herramienta le ayuda a seleccionar y desactivar varias tags/trackers/herramientas de análisis utilizados en este sitio web.',
			},
			consentNotice: {
				learnMore: 'Más',
				description: 'Queremos que nuestro sitio web sea óptimo, mejorar constantemente su rendimiento y ofrecerle contenido adaptado a sus intereses. Para ello, analizamos -con su consentimiento- el uso de nuestro sitio web utilizando herramientas de Tracking y Cookies.',
			},
			purposes: {
				marketing: {
					title: 'Marketing',
					description: 'Los anunciantes utilizan estas tecnologías para publicar anuncios que sean relevantes para sus intereses.',
				},
				functional: {
					title: 'Funcional',
					description: 'Estas tecnologías permiten analizar el uso del sitio web para medir y mejorar el rendimiento.',
				},
				essential: {
					title: 'Esencial',
					description: 'Estas tecnologías son necesarias para activar la funcionalidad básica del sitio web.',
				},
			},
		},
	},
	services: [
		{
			name: 'roomle',
			purposes: ['functional'],
			required: true,
			default: true,
			optOut: false,
			translations: {
				zz: {
					title: 'Roomle'
				},
				de: {
					description: 'Für ein noch besseres Produkterlebnis stellen wir unseren Nutzern einen 3D-Konfigurator zur Verfügung.',
				},
				en: {
					description: 'For our users, we provide a 3D configurator to provide an even better product experience.',
				},
				fr: {
					description: 'Pour nos utilisateurs, nous mettons à disposition un configurateur 3D pour offrir une expérience produit encore meilleure.',
				},
				es: {
					description: 'Para nuestros usuarios, proporcionamos un configurador 3D para brindar una experiencia de producto aún mejor.',
				},
			}
		},
		{
			name: 'vimeo',
			purposes: ['functional'],
			default: true,
			// optOut: true,
			translations: {
				zz: {
					title: 'Vimeo'
				},
				de: {
					description: 'Dies ist ein Dienst zum Anzeigen von Videoinhalten.',
				},
				en: {
					description: 'This is a service for displaying video content.',
				},
				fr: {
					description: 'Il s’agit d’un service d’affichage de contenu vidéo.',
				},
				es: {
					description: 'Este es un servicio para mostrar contenido de video.',
				},
			}
		},
		{
			name: 'hubspot-forms',
			purposes: ['marketing'],
			required: true,
			default: true,
			optOut: false,
			translations: {
				zz: {
					title: 'HubSpot Forms'
				},
				de: {
					description: 'Drag-and-Drop-Formularerstellung.',
				},
				en: {
					description: 'This is a service for creating online forms.',
				},
				fr: {
					description: 'Il s’agit d’un service de création de formulaires en ligne.',
				},
				es: {
					description: 'Este es un servicio para crear formularios en línea.',
				},
			}
		},
		{
			name: 'hubspot',
			purposes: ['marketing'],
			default: false,
			onAccept: `
				const chatButtons = document.querySelectorAll('.a-button[href="#hs-chat-open"]')
				chatButtons.forEach(chatButton => chatButton.toggleAttribute('hidden', false));
			`,
			onDecline: `
				const chatButtons = document.querySelectorAll('.a-button[href="#hs-chat-open"]')
				chatButtons.forEach(chatButton => chatButton.toggleAttribute('hidden', true));
			`,
			translations: {
				zz: {
					title: 'HubSpot',
				},
				de: {
					description: 'Dies ist ein Marketing-, Content-Management-, Webanalyse- und Suchmaschinenoptimierungsdienst.',
				},
				en: {
					description: 'This is a marketing, content management, web analytics and search engine optimization service.',
				},
				fr: {
					description: 'Il s’agit d’un service de marketing, de gestion de contenu, d’analyse Web et d’optimisation des moteurs de recherche.',
				},
				es: {
					description: 'Este es un servicio de marketing, administración de contenido, análisis web y optimización de motores de búsqueda.',
				},
			}
		},
		// {
		// 	name: 'linkedin-plugin',
		// 	purposes: ['functional'],
		// 	optOut: false,
		// 	required: true,
		// 	translations: {
		// 		zz: {
		// 			title: 'LinkedIn Plugin'
		// 		},
		// 		de: {
		// 			description: 'Dieses Tool fügt der Website „Folgen“- und „Teilen“-Schaltflächen für den Social-Media-Dienst LinkedIn hinzu. LinkedIn ist ein soziales Netzwerk, das sich auf professionelles Networking und Karriereentwicklung konzentriert.',
		// 		},
		// 		en: {
		// 			description: 'This is a conversion tracking and retargeting service.',
		// 		},
		// 		fr: {
		// 			description: 'Cet outil ajoute des boutons Suivre et Partager pour le service de médias sociaux LinkedIn au site Web. LinkedIn est un réseau social axé sur le réseautage professionnel et le développement de carrière.',
		// 		},
		// 		es: {
		// 			description: 'Esta herramienta agrega botones Seguir y Compartir para el servicio de redes sociales LinkedIn al sitio web. LinkedIn es una red social que se centra en la creación de redes profesionales y el desarrollo profesional.',
		// 		},
		// 	}
		// },
		{
			name: 'linkedin-insight-tag',
			purposes: ['marketing'],
			// required: true,
			default: false,
			translations: {
				zz: {
					title: 'LinkedIn Insight Tag'
				},
				de: {
					description: 'Dies ist ein Conversion-Tracking- und Retargeting-Dienst. [Dieser Dienst wird automatisch aktiv, wenn „HubSpot“ aktiviert wird]',
				},
				en: {
					description: 'This tool adds Follow and Share buttons for the social media service LinkedIn to the website. LinkedIn is a social network that focuses on professional networking and career development.',
				},
				fr: {
					description: 'Il s’agit d’un service de suivi des conversions et de reciblage.',
				},
				es: {
					description: 'Este es un servicio de seguimiento de conversiones y retargeting.',
				},
			}
		},
		// {
		// 	name: 'cloudflare',
		// 	purposes: ['functional'],
		// 	required: true,
		// 	translations: {
		// 		zz: {
		// 			title: 'Cloudflare'
		// 		},
		// 		de: {
		// 			description: 'Dies ist ein Dienst, der Websites erhöhte Sicherheit und Leistung bietet. Cloudflare bietet beispielsweise ein Content Delivery Network („CDN“) an, um die Ladezeiten der Website zu verbessern. Die Nutzung eines CDN ermöglicht es dem Nutzer, Inhalte mit Hilfe regional oder international verteilter Server schneller zum Abruf bereitzustellen.',
		// 		}
		// 	}
		// },
		{
			name: 'matterport',
			purposes: ['functional'],
			default: true,
			// optOut: true,
			translations: {
				zz: {
					title: 'Matterport'
				},
				de: {
					description: 'Dies ist eine 3D-Medienplattform. Es ermöglicht mehreren Branchen, interaktive 3D- und Virtual-Reality-Räume zu erstellen, zu navigieren, zu modifizieren und zu teilen.',
				},
				en: {
					description: 'This is a 3D media platform. It lets multiple industries create, navigate, modify and share interactive 3D and virtual reality spaces.',
				},
				fr: {
					description: 'Il s’agit d’une plate-forme multimédia 3D. Il permet à plusieurs industries de créer, naviguer, modifier et partager des espaces interactifs 3D et de réalité virtuelle.',
				},
				es: {
					description: 'Esta es una plataforma de medios 3D. Permite que múltiples industrias creen, naveguen, modifiquen y compartan espacios interactivos de realidad virtual y 3D.',
				},
			}
		},
		{
			name: 'plausible',
			purposes: ['functional'],
			required: true,
			translations: {
				zz: {
					title: 'Plausible'
				},
				de: {
					description: 'Wir nutzen Plausible, um das Nutzungsverhalten unserer Website zu analysieren, um deren Inhalt und Technik fortlaufend zu optimieren. Plausible verfolgt bei der Analyse Ihres Besuchs einen besonders datenschutzfreundlichen Ansatz. Zu diesem Zweck erhebt Plausible unter anderem folgende Informationen: Datum und Uhrzeit Ihres Besuchs, Titel und URL der besuchten Seiten, eingehende Links, das Land, in dem Sie sich befinden, und der User Agent Ihrer Browser-Software. Plausible verwendet oder speichert keine „Cookies“ auf Ihrem Endgerät. Alle personenbezogenen Daten (z. B. Ihre IP-Adresse) werden vollkommen anonym in Form eines sogenannten Hashs gespeichert. Ein Hash ist eine Verschlüsselung von Daten, die nicht umkehrbar ist, also nicht „entschlüsselt“ werden kann. Auf diese Weise können wir Ihren Besuch analysieren, ohne personenbezogene Daten in einer für uns lesbaren, plausiblen oder Dritten Form zu speichern.',
				},
				en: {
					description: 'We use Plausible to analyze the usage behavior of our website to continuously optimize its content and technology. Plausible takes a particularly privacy-friendly approach to analyzing your visit. For this purpose, Plausible collects the following information, among others: Date and time of your visit, title and URL of the pages visited, incoming links, the country you are in and the user agent of your browser software. Plausible does not use or store "cookies" on your terminal device. All personal data (e.g. your IP address) is stored completely anonymously in the form of a so-called hash. A hash is an encryption of data that is not reversible, i.e. cannot be "decrypted". In this way, we can analyze your visit without storing personal data in a form that would be readable by us, plausible or third parties.',
				},
				fr: {
					description: 'Nous utilisons Plausible pour analyser le comportement d’utilisation de notre site Web afin d’optimiser en permanence son contenu et sa technologie. Plausible adopte une approche particulièrement respectueuse de la vie privée pour analyser votre visite. A cet effet, Plausible collecte entre autres les informations suivantes : la date et l’heure de votre visite, le titre et l’URL des pages visitées, les liens entrants, le pays dans lequel vous vous trouvez et l’agent utilisateur de votre logiciel de navigation. Plausible n’utilise ni ne stocke de «cookies» sur votre terminal. Toutes les données personnelles (par exemple votre adresse IP) sont stockées de manière totalement anonyme sous la forme d’un hachage. Un hachage est un cryptage de données qui n’est pas réversible, c’est à dire qui ne peut être « déchiffré ». De cette manière, nous pouvons analyser votre visite sans stocker de données personnelles sous une forme lisible par nous, plausible ou par des tiers.',
				},
				es: {
					description: 'Utilizamos Plausible para analizar el comportamiento de uso de nuestro sitio web para optimizar continuamente su contenido y tecnología. Plausible adopta un enfoque particularmente respetuoso con la privacidad al analizar su visita. Para ello, Plausible recopila, entre otros, la siguiente información: Fecha y hora de su visita, título y URL de las páginas visitadas, enlaces entrantes, el país en el que se encuentra y el agente de usuario de su software de navegación. Plausible no utiliza ni almacena "cookies" en su dispositivo terminal. Todos los datos personales (p. ej., su dirección IP) se almacenan de forma totalmente anónima en forma del llamado hash. Un hash es un cifrado de datos que no es reversible, es decir, que no se puede "descifrar". De esta manera, podemos analizar su visita sin almacenar datos personales en una forma que sea legible para nosotros, plausible o para terceros.',
				},
			}
		},
		// {
		// 	name: 'gstatic',
		// 	purposes: ['functional'],
		// 	required: true,
		// 	translations: {
		// 		zz: {
		// 			title: 'gstatic.com'
		// 		},
		// 		de: {
		// 			description: 'Dies ist eine Domain, die von Google verwendet wird, um statische Inhalte auf einen anderen Domainnamen auszulagern, um die Bandbreitennutzung zu reduzieren und die Netzwerkleistung für den Endbenutzer zu erhöhen.',
		// 		},
		// 		en: {
		// 			description: 'This is a domain used by Google to off-load static content to a different domain name in an effort to reduce bandwidth usage and increase network performance for the end user.',
		// 		},
		// 		fr: {
		// 			description: 'Il s’agit d’un domaine utilisé par Google pour décharger le contenu statique vers un nom de domaine différent dans le but de réduire l’utilisation de la bande passante et d’augmenter les performances du réseau pour l’utilisateur final.',
		// 		},
		// 		es: {
		// 			description: 'Este es un dominio utilizado por Google para descargar contenido estático a un nombre de dominio diferente en un esfuerzo por reducir el uso de ancho de banda y aumentar el rendimiento de la red para el usuario final.',
		// 		},
		// 	}
		// },
		{
			name: 'stripe',
			purposes: ['functional'],
			required: true,
			translations: {
				zz: {
					title: 'Stripe'
				},
				de: {
					description: 'Stripe wird für die Kreditkartenzahlung in unserem Onlinshop verwendet.',
				},
				en: {
					description: 'Stripe wird für die Kreditkartenzahlung in unserem Onlinshop verwendet.',
				},
				fr: {
					description: 'Stripe wird für die Kreditkartenzahlung in unserem Onlinshop verwendet.',
				},
				es: {
					description: 'Stripe wird für die Kreditkartenzahlung in unserem Onlinshop verwendet.',
				},
			}
		},
		{
			name: 'klaro',
			purposes: ['functional'],
			required: true,
			translations: {
				zz: {
					title: 'Klaro Consent-Einstellungen'
				},
				de: {
					description: 'Speicherung der in diesem Consent-Banner getroffenen Einstellungen.',
				},
				en: {
					description: 'Saves the settings made in this consent banner.',
				},
				fr: {
					description: 'Sauvegarde les réglages effectués dans cette bannière de consentement.',
				},
				es: {
					description: 'Guarda los ajustes realizados en este banner de consentimiento.',
				},
			}
		},
	],
};

// we assign the Klaro module to the window, so that we can access it in JS
window.klaro = Klaro;

// we set up Klaro with the config
Klaro.setup(config);

/** Needs  timeout otherwise, iframes won’t load correctly, for some reason, tobias.wolf@wagnerwagner.de */
setTimeout(() => {
	window.klaro.getManager().watch({ update: (obj, name, data) => {
		if (name === 'consents') {
			if (data.hubspot === true && data['linkedin-insight-tag'] !== true) {
				window.klaro.getManager().updateConsent('linkedin-insight-tag', true)
			} else if (data.hubspot === false && data['linkedin-insight-tag'] !== false) {
				window.klaro.getManager().updateConsent('linkedin-insight-tag', false)
			}
		}
	}});
}, 200);
